import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Events, AlertController, LoadingController, NavController, ToastController, ModalController } from '@ionic/angular';
import { LabelService } from 'src/app/services/label/label.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { AdminSettingsService } from 'src/app/services/admin-settings/admin-settings.service';
import { StripePage } from '../payment-ui/stripe/stripe.page';
import { OrderService } from 'src/app/services/order/order.service';
import { PaypalPage } from '../payment-ui/paypal/paypal.page';
import { SharedService } from 'src/app/services/shared/shared.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { UpiManualPaymentPage } from '../upi-manual-payment/upi-manual-payment.page';
import { CustomPaymentOptionPage } from '../custom-payment-option/custom-payment-option.page';
import { Storage } from '@ionic/storage';
export class PaymentModulePage {
    constructor(events, alertController, loadingController, navCtrl, toastController, labelService, configService, adminSettingsService, modalController, orderService, sharedService, paymentService, storage) {
        this.events = events;
        this.alertController = alertController;
        this.loadingController = loadingController;
        this.navCtrl = navCtrl;
        this.toastController = toastController;
        this.labelService = labelService;
        this.configService = configService;
        this.adminSettingsService = adminSettingsService;
        this.modalController = modalController;
        this.orderService = orderService;
        this.sharedService = sharedService;
        this.paymentService = paymentService;
        this.storage = storage;
        this.paytmActive = false;
        this.razorpayActive = false;
        this.showLoader = true;
        this.isCod = false;
        this.walletActive = true;
        this.minOrderAmntToUseWallet = 0;
        this.maxWalletAmntPerOrder = 1000;
        this.walletUsed = false;
        this.walletAmount = 0;
        this.envPaytmActive = true;
        this.SHARED_LABELS = {};
        this.AUTO_CONFIRM_PAYMENT_LABELS = {};
        this.upiManual = {
            active: false,
            upiId: '',
            qrCode: '',
            successRoute: ''
        };
        this.cashbackAmount = 0;
        this.cashbackBalance = 0;
        this.isCodAvailableForCoupon = true;
        this.codPercent = 100;
        this.partialPayment = {
            status: false,
            cod: 0,
            online: {
                amount: 0,
                completed: false
            }
        };
        this.paymentType = 'full'; // value: full || partial
        this.customOption = {
            active: false,
            name: '',
            details: '',
            image: {
                url: ''
            },
            textMandatory: false,
            imageMandatory: false,
            successRoute: ''
        };
        this.stripeData = {
            active: false
        };
        this.paymentGateways = ['paypal', 'cashfree'];
        this.paypalObj = {
            active: false
        };
        this.cashfreeObj = {
            active: false
        };
        this.extraCharge = {
            cash: { charge: 0, type: 'flat', chargeName: '', maxCharge: 0 },
            razorpay: { charge: 0, type: 'flat', chargeName: '', maxCharge: 0 },
            paytm: { charge: 0, type: 'flat', chargeName: '', maxCharge: 0 },
            stripe: { charge: 0, type: 'flat', chargeName: '', maxCharge: 0 },
            paypal: { charge: 0, type: 'flat', chargeName: '', maxCharge: 0 },
            cashfree: { charge: 0, type: 'flat', chargeName: '', maxCharge: 0 }
        };
        this.isExternalOrder = false;
        // this.route.queryParams.subscribe(params => {
        //     if (this.router.getCurrentNavigation().extras.state) {
        //         this.isCodAvailableForCoupon = this.router.getCurrentNavigation().extras.state.isCodAvailableForCoupon;
        //     }
        // });
    }
    ngOnInit() {
        this.envPaytmActive = this.configService.environment.envPaytmActive;
    }
    ionViewDidEnter() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.storage.get('isExternalOrder').then((value) => {
                console.log("isExternalOrder value", value);
                if (value) {
                    this.isExternalOrder = value || false;
                }
            });
            this.events.publish('admin-settings:getPaytmData');
            this.events.publish('admin-settings:getRazorPayData');
            this.events.publish('admin-settings:getPaymentInfoData');
            if (!this.isExternalOrder) {
                this.events.publish('wallet:getUserWalletDetails', this.paymentData.order.userId);
                this.events.publish('wallet:getWalletSettings');
            }
            this.initializeSubscriptions();
            this.currencyCode = this.configService.environment.currencyCode;
            this.taxType = this.configService.environment.taxType;
            this.SHARED_LABELS = this.labelService.labels['SHARED'];
            this.AUTO_CONFIRM_PAYMENT_LABELS = this.labelService.labels['AUTO_CONFIRM_PAYMENT'];
            this.getStripeDetails();
            this.getGateways();
        });
    }
    ionViewWillLeave() {
        this.removeSubscription();
    }
    initializeSubscriptions() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.events.subscribe('user:setPaymentModeOfOrderByUserSuccessfully', () => {
                this.loading.dismiss();
                this.presentAlert(`${this.AUTO_CONFIRM_PAYMENT_LABELS['payment_mode_set_msg']}`);
            });
            this.events.subscribe('admin-settings:publishPaytmData', (data) => {
                if (data) {
                    this.paytmActive = data.active;
                    this.extraCharge.paytm = 'extraChargePaytm' in data ? data.extraChargePaytm : this.getInitObjForExtraCharge();
                }
            });
            this.events.subscribe('admin-settings:publishRazorPayData', (data) => {
                if (data && data.active && data.id !== '') {
                    this.razorpayActive = data.active;
                    this.razorpayId = data.id;
                    this.extraCharge.razorpay = 'extraChargeRazorpay' in data ? data.extraChargeRazorpay : this.getInitObjForExtraCharge();
                }
                this.showLoader = false;
            });
            this.events.subscribe('admin-settings:publishPaymentInfoData', (data) => {
                let isCod = typeof data.isCod !== 'undefined' ? data.isCod : true;
                let extraNoCodChecks = false;
                // if (isCod) {
                //     for (const pdt of this.orderData.products) {
                //         if ((pdt.hasOwnProperty('orderType') && pdt.orderType === 'membership') || ('isCod' in pdt && !pdt.isCod)) {
                //             extraNoCodChecks = true;
                //             break;
                //         }
                //     }
                // }
                if (extraNoCodChecks || !this.isCodAvailableForCoupon) {
                    isCod = false;
                }
                this.isCod = isCod;
                this.upiManual = data.hasOwnProperty('upiManual') ? data.upiManual : this.upiManual;
                this.codPercent = 'codPercentage' in data ? data.codPercentage : 100;
                this.partialPayment.status = this.codPercent < 100 ? true : false;
                this.customOption = 'custom' in data ? data.custom : this.customOption;
                this.extraCharge.cash = 'extraCharge' in data ? data.extraCharge : this.getInitObjForExtraCharge();
                this.isGstApplicable = 'isGstApplicable' in data ? data.isGstApplicable : false;
            });
            this.events.subscribe('order:ac_modeSetToCashSuccess', () => {
                this.loading.dismiss();
                this.presentAlert(`${this.AUTO_CONFIRM_PAYMENT_LABELS['order_placed_successfully']}`);
            });
            this.events.subscribe('order:ac_completePaymentWithWalletSuccess', () => {
                this.loading.dismiss();
                this.presentAlert(`${this.AUTO_CONFIRM_PAYMENT_LABELS['order_placed_successfully']}`);
            });
            this.events.subscribe('wallet:publishUserWalletDetails', (data) => {
                if (data) {
                    this.walletBalance = data.wallet ? data.wallet.balance : 0;
                    this.cashbackBalance = data.wallet && data.wallet.cashback && data.wallet.cashback > 0 ? data.wallet.cashback : 0;
                    console.log('walletBalance', this.walletBalance);
                }
            });
            this.events.subscribe('wallet:publishWalletSettings', (data) => {
                console.log('publishWalletSettings', data);
                if (!this.isEmptyObj(data)) {
                    this.walletActive = typeof data.active !== 'undefined' ? data.active : true;
                    this.minOrderAmntToUseWallet = data.minOrderAmnt ? data.minOrderAmnt : 0;
                    this.maxWalletAmntPerOrder = data.maxWalletAmntPerOrder ? data.maxWalletAmntPerOrder : 1000;
                }
            });
            for (const paymentGateway of this.paymentGateways) {
                let paymentGatewayData = yield this.adminSettingsService.getPaymentGateway(paymentGateway);
                if (paymentGatewayData && paymentGatewayData.active) {
                    if (paymentGateway == 'paypal') {
                        this.paypalObj = paymentGatewayData;
                    }
                    if (paymentGateway == 'cashfree') {
                        this.cashfreeObj = paymentGatewayData;
                    }
                }
            }
        });
    }
    completePaymentWithWallet() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alertRes = yield this.sharedService.presentAlertConfirm(this.SHARED_LABELS['payment_mode_alert_msg']);
            if (!alertRes) {
                return;
            }
            yield this.sharedService.presentLoading();
            this.paymentData.order.walletAmount = this.walletAmount;
            this.paymentData.order.cashbackAmount = this.cashbackAmount;
            const paymentRes = yield this.paymentService.walletMethod(this.paymentData);
            paymentRes.response.extraChargeOnPayment = { charge: 0, name: '', gst: 0 };
            this.sharedService.dismissLoading();
            this.paymentService.handlePaymentRes(paymentRes, this.paymentData);
            // this.events.publish('order:ac_completePaymentWithWallet', this.orderData);
        });
    }
    onClickPaymentMode(mode, method = '') {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alertRes = yield this.sharedService.presentAlertConfirm(this.SHARED_LABELS['payment_mode_alert_msg']);
            if (!alertRes) {
                return;
            }
            this.checkPartialPayment();
            this.paymentData.order.walletAmount = this.walletAmount;
            this.paymentData.order.cashbackAmount = this.cashbackAmount;
            let paymentRes = {};
            let extraChargeOnPayment = { charge: 0, name: '', gst: 0 };
            if (mode in this.extraCharge) {
                extraChargeOnPayment = this.getExtraChargeAmount(this.extraCharge[mode]);
            }
            yield this.sharedService.presentLoading();
            switch (mode) {
                case 'cash':
                    paymentRes = yield this.paymentService.cashMethod(this.paymentData);
                    break;
                case 'razorpay':
                    this.paymentData = Object.assign({}, this.paymentData, { paymentCreds: { key: this.razorpayId } });
                    this.paymentData.method = method;
                    paymentRes = yield this.paymentService.razorpayMethod(this.paymentData);
                    break;
                case 'paytm':
                    paymentRes = yield this.paymentService.paytmMethod(this.paymentData);
                    break;
                case 'upiManual':
                    this.sharedService.dismissLoading();
                    this.upiManual.successRoute = this.paymentData.successRoute;
                    const paymentImgRes = yield this.openUpiManualModal(this.upiManual);
                    yield this.sharedService.presentLoading();
                    paymentRes = yield this.paymentService.upiManaulMethod(this.paymentData, paymentImgRes.paymentImg);
                    break;
                case 'custom':
                    this.sharedService.dismissLoading();
                    this.customOption.successRoute = this.paymentData.successRoute;
                    const customOptionResponse = yield this.openCustomOptionModal(this.customOption);
                    yield this.sharedService.presentLoading();
                    paymentRes = yield this.paymentService.customOptionMethod(this.paymentData, customOptionResponse.response);
                    break;
                case 'stripe':
                    // this.presentStripeModal();
                    break;
                case 'paypal':
                    // this.presentPaypalModal()
                    break;
                case 'cashfree':
                    // this.payWithCashfree();
                    break;
            }
            paymentRes.response.extraChargeOnPayment = extraChargeOnPayment;
            paymentRes.response.walletAmount = this.walletAmount;
            paymentRes.response.cashbackAmount = this.cashbackAmount;
            this.sharedService.dismissLoading();
            this.paymentService.handlePaymentRes(paymentRes, this.paymentData);
        });
    }
    getExtraChargeAmount(extraChargeObj) {
        let extraCharge = 0;
        extraChargeObj = this.paymentType === 'partial' ? this.extraCharge.cash : extraChargeObj;
        let gst = 0;
        if (Object.keys('extraChargeObj').length && extraChargeObj.charge) {
            if (extraChargeObj.type === 'flat') {
                extraCharge = extraChargeObj.charge;
            }
            else {
                extraCharge = (this.paymentData.order.amount - (this.walletAmount + this.cashbackAmount)) * (extraChargeObj.charge / 100);
                if (extraCharge > extraChargeObj.maxCharge) {
                    extraCharge = extraChargeObj.maxCharge;
                }
            }
            this.paymentData.order.amount += extraCharge;
            gst = this.calcGst(extraCharge);
        }
        return { charge: extraCharge, name: extraChargeObj.chargeName || '', gst };
    }
    calcGst(extraCharge) {
        return (extraCharge - (extraCharge / (1 + (this.paymentData.order.gst.value / 100))));
        // this.orderData.defaultGst += gstOnExtraCharge;
    }
    // calcGst(extraCharge) {
    //     let allGst = [];
    //     let gstOnExtraCharge = 0;
    //     this.orderData.products.map((p) => {
    //         if (p.gst && this.isGstApplicable) {
    //             allGst.push(p.gst);}
    //     });
    //     if (allGst.length) {
    //         let minGst = 0;
    //         minGst = Math.min(...allGst);
    //         gstOnExtraCharge = (extraCharge - (extraCharge / (1 + (minGst / 100))));
    //         this.orderData.defaultGst += gstOnExtraCharge;
    //         this.orderData.deliveryGstObj.extraChargeGst = (this.orderData.deliveryGstObj.extraChargeGst || 0) + gstOnExtraCharge;
    //     }
    // }
    payWithCashfree() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.orderService.createOrderCashfree(this.paymentData, true);
        });
    }
    checkPartialPayment() {
        const partial = Object.assign({}, this.partialPayment);
        if (this.paymentType === 'partial') {
            partial.status = true;
        }
        else {
            partial.status = false;
        }
        this.paymentData.order.partialPayment = partial;
    }
    isEmptyObj(object) {
        for (var key in object) {
            if (object.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }
    onClickUseWallet() {
        if (this.walletUsed) {
            this.walletUsed = false;
            this.walletAmount = 0;
            this.cashbackAmount = 0;
        }
        else {
            this.getUsableWalletAmnt();
            this.walletUsed = true;
        }
    }
    getUsableWalletAmnt() {
        let walletUsed = 0;
        let cashbackUsed = 0;
        if (this.paymentData.order.amount < this.minOrderAmntToUseWallet) {
            walletUsed = this.paymentData.order.amount >= this.walletBalance ? this.walletBalance : this.paymentData.order.amount;
            this.walletAmount = walletUsed;
        }
        else {
            cashbackUsed = this.paymentData.order.amount >= this.cashbackBalance ? this.cashbackBalance : this.paymentData.order.amount;
            cashbackUsed = cashbackUsed > this.maxWalletAmntPerOrder ? this.maxWalletAmntPerOrder : cashbackUsed;
            this.cashbackAmount = cashbackUsed;
            if (this.paymentData.order.amount - this.cashbackAmount > 0) {
                walletUsed = (this.paymentData.order.amount - this.cashbackAmount) >= this.walletBalance ? this.walletBalance : (this.paymentData.order.amount - this.cashbackAmount);
                this.walletAmount = walletUsed;
            }
        }
    }
    setPaymentType(type) {
        this.paymentType = type;
        if (type === 'partial') {
            this.partialPayment['cod'] = parseFloat((this.getFinalAmount() * (this.codPercent / 100)).toFixed(2));
            this.partialPayment['online']['amount'] = parseFloat((this.getFinalAmount() - this.partialPayment['cod']).toFixed(2));
        }
    }
    getFinalAmount() {
        return this.paymentData.order.amount - (this.walletAmount + this.cashbackAmount);
    }
    presentToast(msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const toast = yield this.toastController.create({
                message: msg,
                duration: 2000,
            });
            toast.present();
        });
    }
    presentAlert(msg, page = 'tabs/tabs/user-order-history') {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                message: msg,
                backdropDismiss: false,
                buttons: [{
                        text: this.SHARED_LABELS['ok'],
                        handler: () => {
                            this.navCtrl.navigateRoot([page]);
                        }
                    }]
            });
            yield alert.present();
        });
    }
    presentLoading() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = yield this.loadingController.create({
                message: this.SHARED_LABELS['please_wait'],
            });
            yield this.loading.present();
        });
    }
    getStripeDetails() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.stripeData = yield this.adminSettingsService.getStripeData();
            this.extraCharge.stripe = 'extraChargeStripe' in this.stripeData ? this.stripeData.extraChargeStripe : this.getInitObjForExtraCharge();
        });
    }
    presentStripeModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalController.create({
                component: StripePage,
                componentProps: {
                    orderData: this.paymentData,
                    stripeData: this.stripeData,
                    autoConfirm: true
                }
            });
            yield modal.present();
        });
    }
    presentPaypalModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalController.create({
                component: PaypalPage,
                cssClass: 'custom-modal',
                componentProps: {
                    orderData: this.paymentData,
                    autoConfirm: true,
                    paypalObj: this.paypalObj
                }
            });
            yield modal.present();
        });
    }
    getGateways() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (const paymentGateway of this.paymentGateways) {
                let paymentGatewayData = yield this.adminSettingsService.getPaymentGateway(paymentGateway);
                if (paymentGatewayData && paymentGatewayData.active) {
                    if (paymentGateway == 'paypal') {
                        this.paypalObj = paymentGatewayData;
                        this.extraCharge.paypal = 'extraChargePaypal' in this.paypalObj ? this.paypalObj.extraChargePaypal : this.getInitObjForExtraCharge();
                    }
                    if (paymentGateway == 'cashfree') {
                        this.cashfreeObj = paymentGatewayData;
                        this.extraCharge.cashfree = 'extraChargeCashfree' in this.cashfreeObj ? this.cashfreeObj.extraChargeCashfree : this.getInitObjForExtraCharge();
                    }
                }
            }
        });
    }
    getInitObjForExtraCharge() {
        return { charge: 0, type: 'flat', chargeName: '', maxCharge: 0 };
    }
    openUpiManualModal(upiManual) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const modal = yield this.modalController.create({
                    component: UpiManualPaymentPage,
                    backdropDismiss: false,
                    componentProps: { upiManual, pageType: 'modal' }
                });
                modal.onDidDismiss().then((res) => {
                    if (res && res.data && !res.data.closed && res.data.paymentImg) {
                        resolve({ paymentImg: res.data.paymentImg });
                    }
                    else {
                        resolve({ paymentImg: '' });
                    }
                });
                yield modal.present();
            }));
        });
    }
    openCustomOptionModal(customOption) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const modal = yield this.modalController.create({
                    component: CustomPaymentOptionPage,
                    backdropDismiss: false,
                    componentProps: { customOption, pageType: 'modal' }
                });
                modal.onDidDismiss().then((res) => {
                    if (res && res.data && !res.data.closed && res.data.response) {
                        resolve({ response: res.data.response });
                    }
                    else {
                        resolve({ response: null });
                    }
                });
                yield modal.present();
            }));
        });
    }
    closeModal() {
        this.modalController.dismiss();
        if (this.paymentData.successRoute) {
            this.navCtrl.navigateRoot([this.paymentData.successRoute]);
        }
    }
    removeSubscription() {
        this.events.unsubscribe('user:setPaymentModeOfOrderByUserSuccessfully');
        this.events.unsubscribe('admin-settings:publishPaytmData');
        this.events.unsubscribe('admin-settings:publishRazorPayData');
        this.events.unsubscribe('admin-settings:publishPaymentInfoData');
        this.events.unsubscribe('order:ac_modeSetToCashSuccess');
        this.events.unsubscribe('wallet:publishUserWalletDetails');
        this.events.unsubscribe('wallet:publishWalletSettings');
        this.events.unsubscribe('order:ac_completePaymentWithWalletSuccess');
    }
}
