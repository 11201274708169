import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { Events } from '@ionic/angular';
import { map, first } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage/';
import { environment } from 'src/environments/environment';
import * as firebase from 'firebase';
import { ConfigService } from '../config/config.service';
import { Storage } from '@ionic/storage';
import { SharedService } from '../shared/shared.service';
import { convertSnaps } from '../db-utils';
import { SearchEngineService } from '../search-engine/search-engine.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@ionic/angular";
import * as i3 from "@ionic/storage";
import * as i4 from "../config/config.service";
import * as i5 from "@angular/fire/storage";
import * as i6 from "../shared/shared.service";
import * as i7 from "../search-engine/search-engine.service";
export class ProductService {
    // lastResponseOfProducts: any;
    // productsWithoutCategoryData: any[] = [];
    constructor(afs, events, storage, configService, fbStorage, sharedService, searchEngineService) {
        this.afs = afs;
        this.events = events;
        this.storage = storage;
        this.configService = configService;
        this.fbStorage = fbStorage;
        this.sharedService = sharedService;
        this.searchEngineService = searchEngineService;
        this.ALGOLIA_APP_ID = this.configService.environment.ALGOLIA_APP_ID;
        this.ALGOLIA_APP_KEY = this.configService.environment.ALGOLIA_APP_KEY;
        this.APP_PROJECT_ID = environment.firebase.projectId;
        this.image = {
            url: null,
            size: null,
            uploadedAt: null,
            productId: null
        };
        this.productsData = [];
        this.productsDataForAdminProducts = [];
    }
    initializeSubscriptions() {
        this.events.subscribe('product:addProduct', (product, listofImages, barcode) => {
            this.addProduct(product, listofImages, barcode);
        });
        this.events.subscribe('product:getProducts', (id, type) => {
            this.getProducts(id, type);
        });
        this.events.subscribe('product:getProductWithId', (id) => {
            this.getProductWithId(id);
        });
        this.events.subscribe('product:updateStatus', (id) => {
            this.updateStatus(id);
        });
        this.events.subscribe('product:editProduct', (editdata, pid, listOfImages, barcode) => {
            this.editProduct(editdata, pid, listOfImages, barcode);
        });
        this.events.subscribe('product:deleteProduct', (id) => {
            this.deleteProduct(id);
        });
        this.events.subscribe('product:loadMoreProducts', (id, type) => {
            this.loadMoreProducts(id, type);
        });
        this.events.subscribe('product:addCategory', (catgeory, categoryImage, status, banner) => {
            this.addCategory(catgeory, categoryImage, status, banner);
        });
        this.events.subscribe('product:getAllCategories', () => {
            this.getAllCategories();
        });
        this.events.subscribe('product:getAllCategoriesForShop', () => {
            this.getAllCategoriesForShop();
        });
        this.events.subscribe('product:deleteCategory', (catgeoryId) => {
            this.deleteCategory(catgeoryId);
        });
        this.events.subscribe('product:editCategory', (categoryData, categoryImage, status, banner) => {
            this.editCategory(categoryData, categoryImage, status, banner);
        });
        this.events.subscribe('product:getProductsForCategory', (cid) => {
            this.getProductsForCategory(cid);
        });
        this.events.subscribe('product:getProductsForCategory', (cid) => {
            this.getProductsForCategory(cid);
        });
        this.events.subscribe('product:getProductsForAdminProducts', () => {
            this.getProductsForAdminProducts();
        });
        this.events.subscribe('product:loadMoreProductsForAdminProducts', () => {
            this.loadMoreProductsForAdminProducts();
        });
        this.events.subscribe('product:loadPreviousProductsForAdminProducts', () => {
            this.loadPreviousProductsForAdminProducts();
        });
        this.events.subscribe('product:getSubcategories', (id) => {
            this.getSubcategories(id);
        });
        this.events.subscribe('product:getSubcategoriesForUser', (id) => {
            this.getSubcategoriesForUser(id);
        });
        this.events.subscribe('product:addSubcategory', (data, image, categoryId, banner) => {
            this.addSubcategory(data, image, categoryId, banner);
        });
        this.events.subscribe('product:editSubcategory', (data, image, subcatId, categoryId, banner) => {
            this.editSubcategory(data, image, subcatId, categoryId, banner);
        });
        this.events.subscribe('product:deleteSubcategory', (subcatId, categoryId) => {
            this.deleteSubcategory(subcatId, categoryId);
        });
        this.events.subscribe('product:getProductsForSubcategory', (subcatId) => {
            this.getProductsForSubcategory(subcatId);
        });
        this.events.subscribe('product:changeSubcategoriesStatus', (status, catId) => {
            this.changeSubcategoriesStatus(status, catId);
        });
        this.events.subscribe('product:getAnalyticsProductsCount', () => {
            this.getAnalyticsProductsCount();
        });
        this.events.subscribe('product:makeProductCopies', (copies, product) => {
            this.makeProductCopies(copies, product);
        });
        this.events.subscribe('product:getCategoriesData', (cid) => {
            this.getCategoriesData(cid);
        });
        this.events.subscribe('product:getSubCategoriesData', (cid, scid) => {
            this.getSubCategoriesData(cid, scid);
        });
        this.setCategoriesInLocalStorage();
        this.events.subscribe('product:removeSusbcriptions', () => {
            if (this.productSub) {
                this.productSub.unsubscribe();
            }
        });
        this.lopRef = this.afs.doc(`listofProducts/list`);
        this.mediaRef = this.afs.collection('media');
        this.productRef = this.afs.collection('products', ref => ref.orderBy('sortedAt', 'desc'));
        this.userRef = this.afs.collection('users');
    }
    addProduct(product, listofImages, barcode) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let colorObj = {};
                let priceList = [];
                if (barcode === '') {
                    product['barcode'] = '';
                }
                if (product.color.hasOwnProperty('image') && product.color.image !== '' && product.color.image.includes('data:image/jpeg;base64,')) {
                    colorObj = Object.assign({}, product.color);
                    delete product.color.image;
                }
                if (product.isPriceList) {
                    priceList = JSON.parse(JSON.stringify(product.priceList));
                    for (const pl of product.priceList) {
                        if (pl.hasOwnProperty('barcode')) {
                            delete pl.barcode;
                        }
                    }
                }
                const doc_ref = yield this.afs.collection('products').add(product);
                if (listofImages.length !== 0) {
                    yield this.addimgtoFirebase(doc_ref.id, listofImages);
                }
                else {
                    yield this.afs.collection('products').doc(doc_ref.id).update({
                        coverPic: {
                            url: 'assets/img/placeholder-img.jpg',
                            mob: 'assets/img/placeholder-img.jpg',
                            thumb: 'assets/img/placeholder-img.jpg'
                        }
                    });
                }
                if (barcode !== '') {
                    const imgRef = this.fbStorage.ref(`products/${doc_ref.id}/barcode/` + new Date().getTime().toString() + '.png');
                    yield imgRef.putString(barcode, 'data_url');
                    const downloadURL = yield imgRef.getDownloadURL().pipe(first()).toPromise();
                    yield this.afs.collection('products').doc(doc_ref.id).update({
                        barcode: downloadURL
                    });
                }
                if (colorObj.hasOwnProperty('image')) {
                    const imgRef = this.fbStorage.ref(`products/${doc_ref.id}/color/` + new Date().getTime().toString() + '.png');
                    yield imgRef.putString(colorObj.image, 'data_url');
                    const downloadURL = yield imgRef.getDownloadURL().pipe(first()).toPromise();
                    yield this.afs.collection('products').doc(doc_ref.id).update({
                        color: {
                            image: downloadURL,
                            name: colorObj.name,
                            code: colorObj.code
                        }
                    });
                }
                if (priceList.length) {
                    for (const pl of priceList) {
                        if (pl.hasOwnProperty('barcode') && pl.barcode !== '') {
                            const imgRef = this.fbStorage.ref(`products/${doc_ref.id}/barcode/` + new Date().getTime().toString() + '.png');
                            yield imgRef.putString(pl.barcode, 'data_url');
                            const downloadURL = yield imgRef.getDownloadURL().pipe(first()).toPromise();
                            pl.barcode = downloadURL;
                        }
                    }
                    yield this.afs.collection('products').doc(doc_ref.id).update({
                        priceList: priceList
                    });
                }
                this.events.publish('product:addSuccess', 'Success', 'Product added successfully. The images will be uploaded in just couple of minutes.');
            }
            catch (error) {
                this.events.publish('product:addFailure', 'Failure', 'Product not added successfully');
            }
        });
    }
    editProduct(editdata, pid, listOfImages, barcode) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let colorObj = {};
            let priceList = [];
            if (editdata.color.hasOwnProperty('image') && editdata.color.image.includes('data:image/jpeg;base64,')) {
                colorObj = Object.assign({}, editdata.color);
                delete editdata.color.image;
            }
            if (editdata.isPriceList) {
                priceList = JSON.parse(JSON.stringify(editdata.priceList));
                for (const pl of editdata.priceList) {
                    if (pl.hasOwnProperty('barcode') && pl.barcode.includes('data:image/jpeg;base64,')) {
                        delete pl.barcode;
                    }
                }
            }
            if (barcode !== '') {
                const imgRef = this.fbStorage.ref(`products/${pid}/barcode/` + new Date().getTime().toString() + '.png');
                yield imgRef.putString(barcode, 'data_url');
                const downloadURL = yield imgRef.getDownloadURL().pipe(first()).toPromise();
                editdata["barcode"] = downloadURL;
                yield this.afs.collection('products').doc(pid).update(editdata);
            }
            else {
                yield this.afs.collection('products').doc(pid).update(editdata);
            }
            if (colorObj.hasOwnProperty('image')) {
                const imgRef = this.fbStorage.ref(`products/${pid}/color/` + new Date().getTime().toString() + '.png');
                yield imgRef.putString(colorObj.image, 'data_url');
                const downloadURL = yield imgRef.getDownloadURL().pipe(first()).toPromise();
                yield this.afs.collection('products').doc(pid).update({
                    color: {
                        image: downloadURL,
                        name: colorObj.name,
                        code: colorObj.code
                    }
                });
            }
            if (priceList.length) {
                for (const pl of priceList) {
                    if (pl.hasOwnProperty('barcode') && pl.barcode.includes('data:image/jpeg;base64,')) {
                        const imgRef = this.fbStorage.ref(`products/${pid}/barcode/` + new Date().getTime().toString() + '.png');
                        yield imgRef.putString(pl.barcode, 'data_url');
                        const downloadURL = yield imgRef.getDownloadURL().pipe(first()).toPromise();
                        pl.barcode = downloadURL;
                    }
                }
                yield this.afs.collection('products').doc(pid).update({
                    priceList: priceList
                });
            }
            if (listOfImages.length !== 0) {
                try {
                    yield this.addimgtoFirebase(pid, listOfImages);
                    this.events.publish('product:editSuccess', 'Success', 'Product edited successfully!');
                }
                catch (error) {
                    this.events.publish('product:editFailure', 'Failure', 'Product not edited successfully!');
                }
            }
            else if (!editdata.images.length && !listOfImages.length) {
                yield this.afs.collection('products').doc(pid).update({
                    coverPic: {
                        url: 'assets/img/placeholder-img.jpg',
                        mob: 'assets/img/placeholder-img.jpg',
                        thumb: 'assets/img/placeholder-img.jpg'
                    }
                });
                this.events.publish('product:editSuccess', 'Success', 'Product edited successfully!');
            }
            else {
                this.events.publish('product:editSuccess', 'Success', 'Product edited successfully!');
            }
        });
    }
    addimgtoFirebase(pid, imgdataAndSize) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (let i = 0; i < imgdataAndSize.length; i++) {
                this.image.url = '';
                this.image.size = imgdataAndSize[i].size;
                this.image.uploadedAt = new Date();
                this.image.productId = pid;
                const mediaDocRef = yield this.mediaRef.doc('images').collection('products').add(this.image);
                const imgRef = this.fbStorage.ref(`products/${pid}/images/` + mediaDocRef.id + '.png');
                yield imgRef.putString(imgdataAndSize[i].base64Img, 'data_url');
                if (imgdataAndSize[i].cover === true) {
                    const downloadURL = yield imgRef.getDownloadURL().pipe(first()).toPromise();
                    this.afs.collection('products').doc(pid).update({ coverPic: { imageId: mediaDocRef.id, url: downloadURL } });
                }
            }
        });
    }
    getProducts(id, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.productsData = [];
            let productRef;
            productRef = this.afs.collection('products', ref => ref
                .where(`${type}`, 'array-contains', id)
                .where('status', '==', true)
                .orderBy('sortedAt', 'desc')
                .limit(this.configService.environment.shopProductsLimit));
            if (type === 'vendor') {
                productRef = this.afs.collection('products', ref => ref
                    .where('vendorId', '==', id)
                    .where('status', '==', true)
                    .orderBy('sortedAt', 'desc')
                    .limit(this.configService.environment.shopProductsLimit));
            }
            else {
                const region = yield this.sharedService.checkRegionIdForApi();
                if (region.vendorId && region.vendorId !== '') {
                    productRef = this.afs.collection('products', ref => ref
                        .where(`${type}`, 'array-contains', id)
                        .where('status', '==', true)
                        .where('vendorId', '==', region.vendorId)
                        .orderBy('sortedAt', 'desc')
                        .limit(this.configService.environment.shopProductsLimit));
                }
            }
            this.productSub = productRef.snapshotChanges()
                .subscribe((response) => {
                if (!response.length) {
                    this.events.publish('product:noProductAvailable');
                    return false;
                }
                this.productsData = [];
                this.lastInResponse = response[response.length - 1].payload.doc;
                for (const product of response) {
                    this.productsData.push({ id: product.payload.doc.id, data: product.payload.doc.data() });
                }
                if (this.productsData.length !== 0) {
                    this.events.publish('product:publishProducts', this.productsData);
                }
                else {
                    this.events.publish('product:noProductAvailable');
                }
                this.productSub.unsubscribe();
            }, error => {
                this.productSub.unsubscribe();
                console.dir(error);
            });
        });
    }
    loadMoreProducts(id, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let loadMoreProductsRef;
            loadMoreProductsRef = this.afs.collection('products', ref => ref
                .where(`${type}`, 'array-contains', id)
                .where('status', '==', true)
                .orderBy('sortedAt', 'desc')
                .limit(this.configService.environment.shopProductsLimit)
                .startAfter(this.lastInResponse));
            if (type === 'vendor') {
                loadMoreProductsRef = this.afs.collection('products', ref => ref
                    .where('vendorId', '==', id)
                    .where('status', '==', true)
                    .orderBy('sortedAt', 'desc')
                    .limit(this.configService.environment.shopProductsLimit)
                    .startAfter(this.lastInResponse));
            }
            else {
                const region = yield this.sharedService.checkRegionIdForApi();
                if (region.vendorId && region.vendorId !== '') {
                    loadMoreProductsRef = this.afs.collection('products', ref => ref
                        .where(`${type}`, 'array-contains', id)
                        .where('status', '==', true)
                        .where('vendorId', '==', region.vendorId)
                        .orderBy('sortedAt', 'desc')
                        .limit(this.configService.environment.shopProductsLimit)
                        .startAfter(this.lastInResponse));
                }
            }
            const loadMoreProductsSub = loadMoreProductsRef.snapshotChanges()
                .subscribe((response) => {
                if (!response.length) {
                    this.events.publish('product:productsLimitReached');
                    return false;
                }
                this.lastInResponse = response[response.length - 1].payload.doc;
                for (const product of response) {
                    this.productsData.push({ id: product.payload.doc.id, data: product.payload.doc.data() });
                }
                this.events.publish('product:publishProducts', this.productsData);
                loadMoreProductsSub.unsubscribe();
            }, error => {
                loadMoreProductsSub.unsubscribe();
            });
        });
    }
    getProductsForAdminProducts() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.productsDataForAdminProducts = [];
            this.afs.collection('products', ref => ref
                .orderBy('sortedAt', 'desc')
                .limit(environment.shopProductsLimit)).snapshotChanges()
                .subscribe((response) => {
                if (!response.length) {
                    this.events.publish('product:noProductsAvailable');
                    return false;
                }
                this.productsDataForAdminProducts = [];
                this.lastResponseForAdminProducts = response[response.length - 1].payload.doc;
                for (const product of response) {
                    this.productsDataForAdminProducts.push({ id: product.payload.doc.id, data: product.payload.doc.data() });
                }
                this.events.publish('product:publishProductsForAdminProducts', this.productsDataForAdminProducts);
            }, error => {
            });
        });
    }
    loadMoreProductsForAdminProducts() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.afs.collection('products', ref => ref
                .orderBy('sortedAt', 'desc')
                .limit(environment.shopProductsLimit)
                .startAfter(this.lastResponseForAdminProducts)).snapshotChanges()
                .subscribe((response) => {
                if (!response.length) {
                    this.events.publish('product:productsForAdminProductsLimitReached');
                    return false;
                }
                this.productsDataForAdminProducts = [];
                this.firstResponseForAdminProducts = response[0].payload.doc;
                this.lastResponseForAdminProducts = response[response.length - 1].payload.doc;
                for (const product of response) {
                    this.productsDataForAdminProducts.push({ id: product.payload.doc.id, data: product.payload.doc.data() });
                }
                this.events.publish('product:publishProductsForAdminProducts', this.productsDataForAdminProducts);
            }, error => {
            });
        });
    }
    loadPreviousProductsForAdminProducts() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.afs.collection('products', ref => ref
                .orderBy('sortedAt', 'desc')
                .endBefore(this.firstResponseForAdminProducts)
                .limitToLast(environment.shopProductsLimit)).snapshotChanges()
                .subscribe((response) => {
                if (!response.length) {
                    this.events.publish('product:previousProductsForAdminProductsLimitReached');
                    return false;
                }
                this.productsDataForAdminProducts = [];
                this.firstResponseForAdminProducts = response[0].payload.doc;
                this.lastResponseForAdminProducts = response[response.length - 1].payload.doc;
                for (const product of response) {
                    this.productsDataForAdminProducts.push({ id: product.payload.doc.id, data: product.payload.doc.data() });
                }
                this.events.publish('product:publishProductsForAdminProducts', this.productsDataForAdminProducts);
            }, error => {
            });
        });
    }
    getProductsForCategory(cid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let productsData = [];
            const productRef = this.afs.collection('products', ref => ref.orderBy('sortedAt', 'desc').where("categories", "array-contains", cid));
            const productSnap = productRef.snapshotChanges().pipe(map(actions => actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return Object.assign({ id }, data);
            })));
            productSnap.subscribe((productsData) => {
                if (productsData.length !== 0) {
                    this.events.publish('product:publishProductsForCategory', productsData);
                }
                else {
                    this.events.publish('product:noProducts');
                }
            });
        });
    }
    getProductWithId(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const productData = yield this.afs.collection('products').doc(id).valueChanges().pipe(first()).toPromise();
            this.events.publish('product:publishgetProductWithId', productData);
        });
    }
    updateproductsPosition(id, changedDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.afs.doc(`products/${id}`).update({ sortedAt: changedDate });
            this.events.publish('product:updateProductPostionSucess');
        });
    }
    updateCategoriesPosition(id, changedDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.afs.doc(`categories/${id}`).update({ sortedAt: changedDate });
            this.events.publish('product:getAllCategories');
            this.events.publish('product:getAllCategoriesForShop');
            this.events.publish('product:updateCategoriesPostionSucess');
        });
    }
    updateStatus(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const uData = yield this.afs.collection('products').doc(id).valueChanges().pipe(first()).toPromise();
            if (uData.status === true) {
                this.afs.doc(`products/${id}`).update({ status: false });
            }
            else {
                this.afs.doc(`products/${id}`).update({ status: true });
            }
        });
    }
    deleteProduct(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.afs.collection('products').doc(id).delete();
                this.events.publish('product:deleteSuccess', 'Success', 'Product deleted successfully!');
            }
            catch (error) {
                this.events.publish('product:deleteFailure', 'Failure', 'Product not deleted successfully!');
            }
        });
    }
    deleteCategory(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.afs.collection('categories').doc(id).delete();
                this.events.publish('product:deleteCategorySuccess');
                this.events.publish('product:getAllCategories');
                this.events.publish('product:getAllCategoriesForShop');
            }
            catch (error) {
                this.events.publish('product:deletecategoryFailure');
            }
        });
    }
    addCategory(catgeoryName, categoryImage, categoryStatus, banner) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const categoryMediaImage = {
                url: null,
                size: null,
                uploadedAt: null,
                categoryId: null
            };
            try {
                const categoryDoc = yield this.afs.collection('categories').add({ name: catgeoryName, totalProducts: 0, sortedAt: new Date(), image: {}, status: categoryStatus });
                if (categoryImage.length !== 0) {
                    categoryMediaImage.url = '';
                    categoryMediaImage.size = categoryImage[0].imgSize;
                    categoryMediaImage.uploadedAt = new Date();
                    categoryMediaImage.categoryId = categoryDoc.id;
                    const mediaDocRef = yield this.afs.collection('media').doc('images').collection('categories').add(categoryMediaImage);
                    const imgRef = this.fbStorage.ref(`categories/${categoryDoc.id}/image/` + mediaDocRef.id + '.png');
                    yield imgRef.putString(categoryImage[0].imgData, 'data_url');
                    const downloadURL = yield imgRef.getDownloadURL().pipe(first()).toPromise();
                    this.afs.collection('media').doc('images').collection('categories').doc(mediaDocRef.id).update({ url: downloadURL });
                    yield this.afs.doc(`categories/${categoryDoc.id}`).update({ image: { url: downloadURL, size: categoryImage[0].imgSize } });
                }
                if (banner.length) {
                    const imgRef = this.fbStorage.ref(`categoriesBanner/${categoryDoc.id}/image/` + new Date().getTime().toString() + '.png');
                    yield imgRef.putString(banner[0].imgData, 'data_url');
                }
                this.events.publish('product:addCategorySuccess');
                this.events.publish('product:getAllCategories');
                this.events.publish('product:getAllCategoriesForShop');
            }
            catch (err) {
                console.dir(err);
            }
        });
    }
    editCategory(catgeoryData, categoryImage, categoryStatus, banner) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const categoryMediaImage = {
                url: null,
                size: null,
                uploadedAt: null,
                categoryId: null
            };
            try {
                yield this.afs.collection('categories').doc(catgeoryData.id).update({ name: catgeoryData.name, status: categoryStatus });
                if (categoryImage.length === 0 && banner.length === 0) {
                    this.events.publish('product:editCategorySuccess');
                    this.events.publish('product:getAllCategories');
                    this.events.publish('product:getAllCategoriesForShop');
                }
                else {
                    if (categoryImage.length) {
                        categoryMediaImage.url = '';
                        categoryMediaImage.size = categoryImage[0].imgSize;
                        categoryMediaImage.uploadedAt = new Date();
                        categoryMediaImage.categoryId = catgeoryData.id;
                        const mediaDocRef = yield this.afs.collection('media').doc('images').collection('categories').add(categoryMediaImage);
                        const imgRef = this.fbStorage.ref(`categories/${catgeoryData.id}/image/` + mediaDocRef.id + '.png');
                        yield imgRef.putString(categoryImage[0].imgData, 'data_url');
                        const downloadURL = yield imgRef.getDownloadURL().pipe(first()).toPromise();
                        this.afs.collection('media').doc('images').collection('categories').doc(mediaDocRef.id).update({ url: downloadURL });
                        yield this.afs.doc(`categories/${catgeoryData.id}`).update({ image: { url: downloadURL, size: categoryImage[0].imgSize } });
                    }
                    if (banner.length) {
                        const imgRef = this.fbStorage.ref(`categoriesBanner/${catgeoryData.id}/image/` + new Date().getTime().toString() + '.png');
                        yield imgRef.putString(banner[0].imgData, 'data_url');
                    }
                    this.events.publish('product:editCategorySuccess');
                    this.events.publish('product:getAllCategories');
                    this.events.publish('product:getAllCategoriesForShop');
                }
            }
            catch (err) {
                console.dir(err);
                this.events.publish('product:editCategoryFailure');
            }
        });
    }
    getAllCategories() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const catgeoryRef = this.afs.collection('categories', ref => ref.orderBy('sortedAt', 'desc'));
            const catgeorySnap = catgeoryRef.snapshotChanges().pipe(map(actions => actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return Object.assign({ id }, data);
            })));
            const catgeoryData = yield catgeorySnap.pipe(first()).toPromise();
            if (!catgeoryData.length) {
                this.events.publish('product:noCategoryAvailable');
            }
            else {
                this.events.publish('product:publishAllCategoriesForAdmin', catgeoryData);
            }
        });
    }
    getAllCategoriesForShop() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let catgeoryRef;
                const region = yield this.sharedService.checkRegionIdForApi();
                let regionId = region.regionId;
                if (regionId) {
                    catgeoryRef = this.afs.collection('categories', ref => ref
                        .orderBy('sortedAt', 'desc')
                        .where('status', '==', true)
                        .where('regionId', 'array-contains', regionId));
                }
                else {
                    catgeoryRef = this.afs.collection('categories', ref => ref
                        .orderBy('sortedAt', 'desc')
                        .where('status', '==', true));
                }
                const catgeorySnap = catgeoryRef.snapshotChanges().pipe(map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return Object.assign({ id }, data);
                })));
                const categoryData = yield catgeorySnap.pipe(first()).toPromise();
                if (!categoryData.length) {
                    this.events.publish('product:noCategoryAvailable');
                }
                else {
                    this.events.publish('product:publishAllCategoriesForShop', categoryData);
                }
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getAllCategoriesForSideMenu() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let catgeoryRef;
                const region = yield this.sharedService.checkRegionIdForApi();
                let regionId = region.regionId;
                if (regionId) {
                    catgeoryRef = this.afs.collection('categories', ref => ref
                        .orderBy('sortedAt', 'desc')
                        .where('status', '==', true)
                        .where('regionId', 'array-contains', regionId));
                }
                else {
                    catgeoryRef = this.afs.collection('categories', ref => ref
                        .orderBy('sortedAt', 'desc')
                        .where('status', '==', true));
                }
                const catgeorySnap = catgeoryRef.snapshotChanges().pipe(map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return Object.assign({ id }, data);
                })));
                const catgeoryData = yield catgeorySnap.pipe(first()).toPromise();
                return catgeoryData;
            }
            catch (error) {
                console.log(error);
            }
        });
    }
    getSubcategories(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const subcategoriesRef = this.afs.collection('categories').doc(id).collection('subcategories', ref => ref.orderBy('sortedAt', 'desc'));
                const subcategoriesSnap = subcategoriesRef.snapshotChanges().pipe(map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return Object.assign({ id }, data);
                })));
                subcategoriesSnap.subscribe((data) => {
                    if (!data.length) {
                        this.events.publish('product:noSubcategories');
                    }
                    else {
                        this.events.publish('product:publishSubcategories', data);
                    }
                });
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    updateSubcategoriesPosition(id, changedDate, catId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.afs.collection('categories').doc(catId).collection('subcategories').doc(id).update({ sortedAt: changedDate });
            this.events.publish('product:updateSubcategoriesPostionSucess');
        });
    }
    addSubcategory(data, categoryImage, catId, banner) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                data['sortedAt'] = firebase.firestore.FieldValue.serverTimestamp();
                if (categoryImage.length === 0) {
                    data['image'] = { url: 'assets/img/placeholder-img.jpg' };
                }
                const subcategoryDoc = yield this.afs.collection('categories').doc(catId).collection('subcategories').add(data);
                if (categoryImage.length !== 0) {
                    const imgRef = this.fbStorage.ref(`subcategories/${catId}/image/${subcategoryDoc.id}/` + new Date().getTime().toString() + '.png');
                    yield imgRef.putString(categoryImage[0].imgData, 'data_url');
                }
                if (banner.length !== 0) {
                    const imgRef = this.fbStorage.ref(`subCategoriesBanner/${catId}/banner/${subcategoryDoc.id}/` + new Date().getTime().toString() + '.png');
                    yield imgRef.putString(banner[0].imgData, 'data_url');
                }
                this.events.publish('product:addSubcategorySuccess');
            }
            catch (err) {
                console.dir(err);
            }
        });
    }
    editSubcategory(data, image, subcatId, catId, banner) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const subcategoryDoc = yield this.afs.collection('categories').doc(catId).collection('subcategories').doc(subcatId).update({
                    name: data.name,
                    status: data.status
                });
                if (image.length !== 0) {
                    const imgRef = this.fbStorage.ref(`subcategories/${catId}/image/${subcatId}/` + new Date().getTime().toString() + '.png');
                    yield imgRef.putString(image[0].imgData, 'data_url');
                }
                if (banner.length !== 0) {
                    const imgRef = this.fbStorage.ref(`subCategoriesBanner/${catId}/banner/${subcatId}/` + new Date().getTime().toString() + '.png');
                    yield imgRef.putString(banner[0].imgData, 'data_url');
                }
                this.events.publish('product:editSubcategorySuccess');
            }
            catch (err) {
                console.dir(err);
            }
        });
    }
    deleteSubcategory(subcatId, catId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.afs.collection('categories').doc(catId).collection('subcategories').doc(subcatId).delete();
                this.events.publish('product:deleteSubcategorySuccess');
            }
            catch (error) {
            }
        });
    }
    getProductsForSubcategory(subcatId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let productsData = [];
            const productRef = this.afs.collection('products', ref => ref.orderBy('sortedAt', 'desc').where("categories", "array-contains", subcatId));
            const productSnap = productRef.snapshotChanges().pipe(map(actions => actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return Object.assign({ id }, data);
            })));
            productSnap.subscribe((productsData) => {
                if (productsData.length !== 0) {
                    this.events.publish('product:publishProductsForSubcategory', productsData);
                }
                else {
                    this.events.publish('product:noProductsForSubcategory');
                }
            });
        });
    }
    changeSubcategoriesStatus(status, catId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.afs.collection('categories').doc(catId).update({
                    isSubcategories: status
                });
                this.events.publish('product:changeSubcategoriesStatusSuccess');
                this.events.publish('product:getAllCategories');
                this.events.publish('product:getAllCategoriesForShop');
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getSubcategoriesInNewProduct(cid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const subcategoriesRef = this.afs.collection('categories').doc(cid).collection('subcategories', ref => ref.orderBy('sortedAt', 'desc'));
                const subcategoriesSnap = subcategoriesRef.snapshotChanges().pipe(map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return Object.assign({ id }, data);
                })));
                const subcategoriesData = yield subcategoriesSnap.pipe(first()).toPromise();
                return subcategoriesData;
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getSubcategoriesForUser(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let catg = yield this.afs.collection('categories').doc(id).valueChanges().pipe(first()).toPromise();
                if (catg !== undefined && catg.isSubcategories) {
                    const subcategoriesRef = this.afs.collection('categories').doc(id).collection('subcategories', ref => ref
                        .orderBy('sortedAt', 'desc')
                        .where('status', '==', true));
                    const subcategoriesSnap = subcategoriesRef.snapshotChanges().pipe(map(actions => actions.map(a => {
                        const data = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return Object.assign({ id }, data);
                    })));
                    subcategoriesSnap.subscribe((data) => {
                        if (!data.length) {
                            this.events.publish('product:noSubcategoriesForUser');
                        }
                        else {
                            this.events.publish('product:publishSubcategoriesForUser', data);
                        }
                    });
                }
                else {
                    this.events.publish('product:noSubcategoriesForUser');
                }
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getAnalyticsProductsCount() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.afs.collection('analytics').doc('products').valueChanges().subscribe((data) => {
                    this.events.publish('product:publishAnalyticsProductsCount', data.count);
                });
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    makeProductCopies(copies, product) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let makeCopies = firebase.functions().httpsCallable('products-makeCopies');
                makeCopies({ copies: copies, product: product }).then((response) => {
                    if (response.data.success) {
                        this.events.publish('product:makeProductCopiesSuccess');
                    }
                    else {
                        this.events.publish('product:makeProductCopiesFailure');
                    }
                });
            }
            catch (error) {
                console.dir(error);
                this.events.publish('product:makeProductCopiesFailure');
            }
        });
    }
    getCategoriesData(cid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const catData = yield this.afs.collection('categories').doc(cid).valueChanges().pipe(first()).toPromise();
                this.events.publish('product:publishCategoriesData', catData);
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getSubCategoriesData(cid, scid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const data = yield this.afs.collection('categories').doc(cid).collection('subcategories').doc(scid).valueChanges().pipe(first()).toPromise();
                this.events.publish('product:publishSubCategoriesData', data);
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getSimilarProducts(product, pid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let similarProducts = [];
            const keywords = product.searchKeywords && product.searchKeywords.length ? product.searchKeywords : [];
            const name = product.prodName || '';
            let query = '*';
            let filters = 'status:true';
            if (keywords.length) {
                // keywords.forEach(kw => {
                //     similarQuery = `${similarQuery} ${kw}`;
                // });
                filters += ` && searchKeywords:=[${keywords}]`;
            }
            const region = yield this.sharedService.checkRegionIdForApi();
            let regionId = region.regionId;
            let vendorId = region.vendorId;
            if (regionId) {
                filters += ` && (categoryRegions:${regionId} || brandRegions:${regionId})`;
            }
            if (vendorId) {
                filters += ` && vendorId:${vendorId}`;
            }
            if (this.configService.environment.useTypesense) {
                console.log('filters', filters);
                const res = yield this.searchEngineService.getSearchProductsFromTypesense(query, 0, 'new_search', filters);
                if (res.status === 'available') {
                    res.products.forEach(product => {
                        product.data = product;
                    });
                    const index = res.products.findIndex(product => product.id === pid);
                    res.products.splice(index, 1);
                    return res.products;
                }
                else {
                    return [];
                }
            }
            else {
                return [];
            }
        });
    }
    getCategoriesWithSubcategoriesForMenu() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let list = [];
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const storageCategories = JSON.parse(localStorage.getItem("categories")) || [];
                if (!storageCategories.length) {
                    list = yield this.setCategoriesInLocalStorage();
                }
                else {
                    list = storageCategories;
                }
                resolve(list);
            }));
        });
    }
    setCategoriesInLocalStorage() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let list = [];
                const region = yield this.sharedService.checkRegionIdForApi();
                let regionId = region.regionId;
                let categoryRef;
                if (regionId) {
                    categoryRef = this.afs.collection('categories', ref => ref
                        .orderBy('sortedAt', 'desc')
                        .where('status', '==', true)
                        .where('regionId', 'array-contains', regionId));
                }
                else {
                    categoryRef = this.afs.collection('categories', ref => ref
                        .orderBy('sortedAt', 'desc')
                        .where('status', '==', true));
                }
                const categories = yield categoryRef.snapshotChanges().pipe(map(snaps => convertSnaps(snaps))).pipe(first()).toPromise();
                for (const c of categories) {
                    if (c.isSubcategories) {
                        const subcategories = yield this.getSubCategoriesForMenu(c.id);
                        let sublist = [];
                        if (subcategories.length) {
                            for (const sc of subcategories) {
                                sublist.push({ id: sc.id, name: sc.name });
                            }
                        }
                        list.push({ id: c.id, name: c.name, sublist });
                    }
                    else {
                        list.push({ id: c.id, name: c.name, sublist: [] });
                    }
                }
                localStorage.setItem("categories", JSON.stringify(list));
                resolve(list);
            }));
        });
    }
    getSubCategoriesForMenu(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const subcategoriesRef = this.afs.collection('categories').doc(id).collection('subcategories', ref => ref.orderBy('sortedAt', 'desc')
                    .where('status', '==', true));
                const subcategories = yield subcategoriesRef.snapshotChanges().pipe(map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return Object.assign({ id }, data);
                }))).pipe(first()).toPromise();
                return subcategories || [];
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    saveAnalytics(pid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const uid = yield this.sharedService.getStorageUid();
            if (uid) {
                const data = {
                    lastAccessAt: new Date(),
                    source: 'web'
                };
                this.afs.collection('users').doc(uid).collection('analytics').doc('products').collection('data').doc(pid).set(data);
            }
            try {
                const docRef = this.afs.collection('products').doc(pid).collection('analytics').doc('data');
                firebase.firestore().runTransaction(t => {
                    return t.get(docRef.ref)
                        .then(doc => {
                        const newViewsCount = doc && doc.data() && doc.data().viewsCount ? doc.data().viewsCount + 1 : 1;
                        t.set(docRef.ref, {
                            viewsCount: newViewsCount
                        });
                    });
                }).then(res => console.log('Transaction completed!'), err => console.error(err));
            }
            catch (error) {
                console.error(error);
            }
        });
    }
    notifyProduct(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const uid = yield this.sharedService.getStorageUid();
            if (data.parentId) {
                this.afs.collection('products').doc(data.parentId).collection('options').doc(data.id).collection('notifications').doc(uid).set({ createdAt: new Date() });
            }
            else {
                this.afs.collection('products').doc(data.id).collection('notifications').doc(uid).set({ createdAt: new Date() });
            }
        });
    }
    getDescriptionSections(productId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    const appSections = [];
                    const sectionsDoc = yield this.afs.collection('products').doc(productId).collection('sections').doc('productWidgets').valueChanges().pipe(first()).toPromise();
                    if (sectionsDoc && sectionsDoc.sections && sectionsDoc.sections.length) {
                        sectionsDoc.sections.forEach(section => {
                            if (section.location === 'app' || section.location === 'all') {
                                appSections.push(section);
                            }
                        });
                    }
                    resolve(appSections);
                }
                catch (error) {
                    console.dir(error);
                    error['location'] = 'product-service:getDescriptionSections';
                    //this.logglyService.log(error);
                    resolve([]);
                }
            }));
        });
    }
    removeSubscriptions() {
        this.events.unsubscribe('product:addProduct');
        this.events.unsubscribe('product:getProducts');
        this.events.unsubscribe('product:getProductWithId');
        this.events.unsubscribe('product:updateStatus');
        this.events.unsubscribe('product:editProduct');
        this.events.unsubscribe('product:deleteProduct');
        this.events.unsubscribe('product:loadMoreProducts');
        this.events.unsubscribe('product:addCategory');
        this.events.unsubscribe('product:getAllCategories');
        this.events.unsubscribe('product:deleteCategory');
        this.events.unsubscribe('product:editCategory');
        this.events.unsubscribe('product:getAllCategoriesForShop');
        this.events.unsubscribe('product:getProductsForCategory');
        this.events.unsubscribe('product:getProductsForAdminProducts');
        this.events.unsubscribe('product:loadMoreProductsForAdminProducts');
    }
}
ProductService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProductService_Factory() { return new ProductService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.Events), i0.ɵɵinject(i3.Storage), i0.ɵɵinject(i4.ConfigService), i0.ɵɵinject(i5.AngularFireStorage), i0.ɵɵinject(i6.SharedService), i0.ɵɵinject(i7.SearchEngineService)); }, token: ProductService, providedIn: "root" });
